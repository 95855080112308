import Cookies from 'js-cookie'
import { useRouter } from 'vue-router'
import { isWebview } from '../service/EnvManager'
import { getQueryVariable } from './util'
import { login, getInfo } from "../api/login";

const TokenKey = 'Admin-Token'
const UserIdKey = 'userId'

const ExpiresInKey = 'Admin-Expires-In'
const UserInfo = "userInfo"
var router = useRouter()
let myInfo = {}
async function getInfoFun() {
  if (isWebview()) {
    const res = await getInfo()
    if (res.code == 200) {
      myInfo = res.user
      setUserInfo(JSON.stringify(myInfo))
    }
  }
}
setTimeout(() => {
  getInfoFun()
}, 200)

export function getToken() {
  if (isWebview()) {
    return getQueryVariable("token")
  } else {
    return Cookies.get(TokenKey)
  }
}

export function setToken(token) {
  if (isWebview()) {

  } else {
    return Cookies.set(TokenKey, token)
  }
}

export function getUserId() {
  if (isWebview()) {
    return getQueryVariable("userId")

  } else {
    if (Cookies.get(UserIdKey)) {
      return parseInt(Cookies.get(UserIdKey))
    } else {
      router.push("/Login")
    }
  }



}
export  function getUserInfo() {
  if (isWebview()) {
    return myInfo
  } else {
    if (Cookies.get(UserInfo)) {
      const user = JSON.parse(Cookies.get(UserInfo))
      return user
    } else {
      router.push("/Login")
    }
  }



}

export function setUserId(userId) {
  return Cookies.set(UserIdKey, userId)
}

export function setUserInfo(userInfo) {
  let user={
    canteenId:userInfo.canteenId,
    canteenName:userInfo.canteenName,
    schoolId:userInfo.schoolId,
    storeId:userInfo.storeId,
    storeName:userInfo.storeName,
    userName:userInfo.userName
  }
  return Cookies.set(UserInfo, JSON.stringify(user))
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeUserId() {
  return Cookies.remove(UserIdKey)
}

export function removeUserInfo() {
  console.log('向汉朝');
  return Cookies.remove(UserInfo)
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey)
}
