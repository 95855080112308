<script setup>
import { reactive, ref ,onMounted} from 'vue'

import {getHomeList} from '../api/requestUtil'

import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import Food from './food/Food.vue'
import RestaurantFood from './restaurantFood/RestaurantFood.vue'
import PlanList from './plan/PlanList.vue'
import My from './my/My.vue'


// console.log("import.meta.env", import.meta.env)

const state = reactive({
  setTab:0,
  tabIconList:[
    ["home/tab1.png", "home/tab1Sel.png"],
    ["home/tab2.png", "home/tab2Sel.png"],
    ["home/tab3.png", "home/tab3Sel.png"],
    ["home/tab4.png", "home/tab4Sel.png"]
  ]
})


const router = useRouter()
const route = useRoute()

// getHomeList().then((res)=>{
//     console.log("获取商品列表", res)
// })

const onChange  = (index)=>{
  var routerList = ["/Food", "/RestaurantFood", "/PlanMain", "/My"]
  router.push(routerList[index])
}
onMounted(()=>{
  console.log(route.path ,router,'23423');
})

onBeforeRouteUpdate((to, from)=>{
  console.log("onBeforeRouteUpdate")
  console.log(to)
})

if(route.path == "/Food"){
  state.setTab = 0
}else if(route.path == "/RestaurantFood"){
  state.setTab = 1
}else if(route.path == "/PlanMain"){
  state.setTab = 2
}else if(route.path == "/My"){
  state.setTab = 3
}


</script>

<template>

    <!-- <Food v-if="state.setTab == 0"></Food>
    <RestaurantFood v-if="state.setTab == 1"></RestaurantFood>
    <PlanList v-if="state.setTab == 2"></PlanList>
    <My v-if="state.setTab == 3"></My> -->

    <router-view></router-view>
    <!-- 根据路由跳转界面 -->
    <van-tabbar  :route="true" active-color="#3ccacb" inactive-color="#777">
      <van-tabbar-item to="/" replace v-if="route.path=='/'" >
        <span class="tabBtnName">公共菜品库</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[0][1] : state.tabIconList[0][0]" class="tabBtnImg" />
        </template>
        
      </van-tabbar-item>

      <van-tabbar-item to="/Food" replace v-else>
        <span class="tabBtnName">公共菜品库</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[0][1] : state.tabIconList[0][0]" class="tabBtnImg" />
        </template>
        
      </van-tabbar-item>
      <van-tabbar-item to="/RestaurantFood" replace >
        <span class="tabBtnName">餐厅菜品库</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[1][1] : state.tabIconList[1][0]" class="tabBtnImg" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/PlanMain" replace >
        <span class="tabBtnName">生产计划</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[2][1] : state.tabIconList[2][0]" class="tabBtnImg" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/My" replace >
        <span class="tabBtnName">个人中心</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[3][1] : state.tabIconList[3][0]" class="tabBtnImg" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    
  
    <!-- <van-tabbar v-model="state.setTab" @change="onChange"  active-color="#3ccacb" inactive-color="#777">
      <van-tabbar-item>
        <span class="tabBtnName">公共菜品库</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[0][1] : state.tabIconList[0][0]" class="tabBtnImg" />
        </template>
        
      </van-tabbar-item>
      <van-tabbar-item>
        <span class="tabBtnName">餐厅菜品库</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[1][1] : state.tabIconList[1][0]" class="tabBtnImg" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span class="tabBtnName">生产计划</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[2][1] : state.tabIconList[2][0]" class="tabBtnImg" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span class="tabBtnName">个人中心</span>
        <template #icon="props">
          <img :src="props.active ? state.tabIconList[3][1] : state.tabIconList[3][0]" class="tabBtnImg" />
        </template>
      </van-tabbar-item>
    </van-tabbar> -->


</template>

<style scoped>

:deep(.van-tabbar-item__icon){
  margin-bottom: 5px;
  margin-top: 10px;
}
.van-tabbar{
  height: 58px;
  padding-bottom: calc(env(safe-area-inset-bottom));
}
:deep(.van-tabbar-item){
  padding-bottom: 8px;
}
.van-hairline--top-bottom:after{
  border-top:1px solid #c9c9c9;
}
.tabBtnName{
  font-size: 12px;
}
:deep(.van-tabbar-item__text){
  transform: scale(0.75);
}
.tabBtnImg{
  width: 25px;
  height: 24.5px;
}

</style>
