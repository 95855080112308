import { defineStore } from "pinia"; //持久化存储
import { TimeManager } from "../service/TimeManager";
import { GlobalConfig } from "../utils/GlobalConfig";
import { isSameDay } from '../utils/util'


export const PlanCarStore = defineStore("PlanCarStore", {
    state: () => {
        return {
            dataList: {},
            todayTimestamp: 0,
            curTimestamp: 0,//当前页面对应的日期
            restaurant: {},//餐厅餐品
            showVal:false
        }
    },
    getters: {

    },
    actions: {
        /**
         * 根据时间字符串获取购物车里对应的东西
         */
        getListByDateStr(dateStr) {
            if (!this.dataList[dateStr]) {
                this.dataList[dateStr] = []
            }
            return this.dataList[dateStr]
        },

        getRestaurant(dateStr) {
            if (!this.restaurant[dateStr]) {
                this.restaurant[dateStr] = []
            }
            return this.restaurant[dateStr]
        },
        async initTime() {
            // console.log("=========initTime==========")
            // if(this.isInit){
            //     return
            // }
            // this.isInit = true
            // this.todayDate = new Date()
            // this.curDateStr = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate()

            // date.setDate(date.getDate() + 1)
            // this.tomorowDateStr = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate()    
        },
        // async isDateChange(){
        //     return new Promise((resolve)=>{
        //         if(this.isChange){
        //             document.getElementById("timeDialog").style.display = "flex"
        //             resolve(true)
        //             return
        //         }
        //         var date = new Date()
        //         var Str = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate()
        //         if(Str != this.curDateStr){
        //             // this.curDateStr = Str
        //             // date.setDate(date.getDate() + 1)
        //             // this.tomorowDateStr = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate()
        //             this.isChange = true
        //             document.getElementById("timeDialog").style.display = "flex"
        //             resolve(true)
        //         }else{
        //             resolve(false)
        //         }

        //     })
        // },
        clear(dateStr, isRest) {
            if (!isRest) {
                var list = this.getListByDateStr(dateStr)
                while (list.length > 0) {
                    list.splice(0, 1)
                }
            } else {
                var list = this.getRestaurant(dateStr)
                while (list.length > 0) {
                    list.splice(0, 1)
                }
            }


        },
        curTimestampIsToday() {
            console.log(this.curTimestamp, this.todayTimestamp,'this.curTimestamp, this.todayTimestamp');
            return isSameDay(this.curTimestamp, this.todayTimestamp)
        }
    },
    // 开启数据缓存
    persist: {

        // 存储所有变量
        // 存储到 localStorage  ，key 为 store 的 id
        enabled: true,
        strategies: [
            {
                key: "PlanCarStore2", //自定义存储 key 值
                storage: localStorage,
                paths: ['dataList', 'restaurant','showVal'],// 存储部分变量
            },
        ]
    }

})