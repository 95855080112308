import { defineStore } from "pinia";
import { login , getInfo} from "../api/login";
import { getUserId, removeToken, removeUserId, removeUserInfo,setToken, setUserId,setUserInfo } from "../utils/auth";

export const mainStore = defineStore("main", {
    state: ()=>{
        return {
            msg:"my pinia",
            userInfo:null
        }
    },
    getters:{},
    actions:{
        async login(userInfo){

            const username = userInfo.username.trim()
            const password = userInfo.password
            const code = userInfo.code
            const uuid = userInfo.uuid
			const loginType = '4';

            try{
                var loginRes = await login(username, password, code, uuid ,loginType)
                let data = loginRes.data
                setToken(data.access_token)

    
                var myInfo =  await getInfo();
                let userInfo=myInfo.user||''
                setUserId(myInfo.user.userId)
                setUserInfo(userInfo)

            }catch(e){
                removeToken()
                removeUserId()
                removeUserInfo()
                throw e
            }


            // return new Promise( (resolve, reject) => {
            //     login(username, password, code, uuid).then((res) => {
            //       let data = res.data
                  
            //       setToken(data.access_token)
            //       var myInfo =  await getInfo();
                  
                  
            //       resolve()
            //     }).catch(error => {
            //         console.log(error)
            //         var eee = error
            //         reject(error)
            //     })
            // })

        }
    }
})