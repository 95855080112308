import { reactive } from 'vue'
import index from '../views/index.vue'
// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
import wx from "weixin-webview-jssdk";

import { isWebview } from "../service/EnvManager"
const Food = () => import('../views/food/Food.vue')
import RestaurantFood from '../views/restaurantFood/RestaurantFood.vue'
const RestaurantFoodMain = () => import('../views/restaurantFood/RestaurantFoodMain.vue')
const MyFoodPanel = () => import('../views/my/MyFood/MyFoodPanel.vue')
const MyFoodEdit = () => import('../views/my/MyFood/MyFoodEdit.vue')
const MyInfo = () => import("../views/my/info/MyInfo.vue")

const PlanMain = () => import('../views/plan/PlanMain.vue')
const My = () => import('../views/my/My.vue')
const Login = () => import('../views/login/Login.vue')
import { getToken } from '../utils/auth';

import { mainStore } from '../store';
const HelloWorld = () => import('../components/HelloWorld.vue')
const ChangePhone = () => import('../views/my/info/ChangePhone.vue')

const complaint = () => import('../views/my/complaint/complaint.vue')
const complaintWrit = () => import('../views/my/complaint/complaintWrit.vue')
const TermPlane = () => import('../views/my/term/TermPlane.vue')
const Setting = () => import('../views/my/setting/Setting.vue')
const Privacy = () => import('../views/my/Privacy.vue')
const about = () => import("../views/my/about.vue")

const termsOfUse = () => import("../views/my/term/termsOfUse.vue")
const UserAgreement = () => import("../views/my/term/UserAgreement.vue")
const PrivacyPolicy = () => import("../views/my/term/PrivacyPolicy.vue")
const UsageRules = () => import("../views/my/term/UsageRules.vue")
const serviceAgreement = () => import("../views/my/term/serviceAgreement.vue")
const businessLicense = () => import("../views/my/term/businessLicense.vue")
const ChangeName = () => import("../views/my/info/ChangeName.vue")
// 溯源码
const traceability = () => import("../views/traceability.vue")

const routes = [
    {
        path: '/',
        name: '',
        component: index,
        children: [
            {
                path: '/',
                name: '',
                component: Food
            },
            {
                path: 'Food',
                component: Food
            }, {
                path: 'RestaurantFood',
                component: RestaurantFoodMain
            }, {
                path: 'PlanMain',
                component: PlanMain
            }, {
                path: 'My',
                component: My
            }
        ]
    }, {

        path: '/Login',
        name: 'Login',
        component: Login
    }, {
        path: "/HelloWorld",
        name: 'HelloWorld',

        component: HelloWorld
    }, {
        path: "/MyFoodPanel",
        component: MyFoodPanel
    }, {
        path: "/MyFoodEdit/:id/:isCreate/:menuType",
        component: MyFoodEdit,
        props: true
    }, {
        path: "/MyInfo",
        component: MyInfo,
    }, {
        path: "/ChangePhone",
        component: ChangePhone
    }, {
        path: "/complaint",
        component: complaint
    }, {
        path: "/complaintWrit/:id",
        component: complaintWrit,
        props: true
    }, {
        path: "/TermPlane",
        component: TermPlane
    }, {
        path: "/Setting",
        component: Setting
    }, {
        path: "/Privacy",
        component: Privacy
    }, {
        path: "/about",
        component: about
    }, {
        path: "/termsOfUse",
        component: termsOfUse
    }, {
        path: "/UserAgreement",
        component: UserAgreement
    }, {
        path: "/PrivacyPolicy",
        component: PrivacyPolicy
    }, {
        path: "/UsageRules",
        component: UsageRules
    }, {
        path: "/serviceAgreement",
        component: serviceAgreement
    }, {
        path: "/businessLicense",
        component: businessLicense
    }, {
        path: "/ChangeName",
        component: ChangeName
    },
    {
        path: "/traceability",
        component: traceability
    }


]


var router = createRouter({
    mode: 'history',
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes: routes
})

router.beforeEach(async (to, from, next) => {
    console.log(to, from, '==', getToken());
    if (to.path === "/Login") {

        next()
    } else if (to.path === "/") {
        next()

    } else {
        var token = getToken()
        if (token) {
            // var store = mainStore()
            // if(!store.userInfo){
            //     router.replace("/Login")//有token, 但没有userInfo，也要跳登录页面
            // }else{

            next()
            // }
        } else {

            // 没有token 可以访问溯源码
            if (to.path === '/traceability') {
                next()
            } else {
                if (isWebview()) {
                    next()
                    return
                }
                //没有token，跳到登录
                router.replace("/Login")
            }

        }

    }
})

console.log("router里面")
export { router }
