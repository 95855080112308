import { getQueryVariable } from '../utils/util'

export var isWebview = () => {


    var isWebView = getQueryVariable("isWebView")
    if (isWebView == "1") {
        return true
    }
    return false


}

export const webViewPag = (text = "pag") => {
    var ispag = getQueryVariable(text)
    if (ispag) {
        return ispag
    }
    return false
}